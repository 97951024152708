import React, { FC } from 'react';
import styled from "styled-components";
import { Link } from 'gatsby';
import { Box, Flex } from 'rebass';
import { H5 } from "../../../components/elements";
import { theme } from "../../../theme";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { stringReplace } from "../../../utils/common";
import { useDispatch } from '../../../store';
import { setServiceData } from '../../../slices/services';

interface SideBarMenuProps {
  item?: any;
}

const SidebarLink = styled(Link)`
  display: flex;
  color: ${(props: any) => (props.theme.colors.white)};
  justify-content: space-between;
  align-items: center;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 20px;
  font-family:  ${(props: any) => props.fontFamily ? props.fontFamily : props.theme.font.families.oswald};
`;

const SideBarMenu: FC<SideBarMenuProps> = ({ item }) => {
  const dispatch = useDispatch();
  return (
    <>
      <SidebarLink to={`/services/${stringReplace(item?.attributes?.title)}`} onClick={() => dispatch(setServiceData({ id: item?.id, name: item?.attributes?.title ?? "" }))}>
        <Flex flexWrap={['nowrap']} justifyContent='space-between'
          sx={{
            width: '100%',
          }}
        >
          <Box m={[2, 3]}
            sx={{
              '@media screen and (max-width: 64em)': {
                width: '100%',
              }
            }}>
            <H5 textColor={theme.colors.white} fontFamily={theme.font.families.oswald}>{item?.attributes?.title}</H5>
          </Box>
          <Box m={[2, 3]}
            sx={{
              '@media screen and (max-width: 64em)': {
                width: '80px',
                textAlign: 'right',
              }
            }}>
            <FontAwesomeIcon icon={faAngleRight} />
          </Box>
        </Flex>
      </SidebarLink>
    </>
  );
};

export default SideBarMenu;